import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  AutoComplete,
  Divider,
  message,
  Select,
  Row,
  Col,
} from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import config from "../../api_config.json";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} è obbligatorio!",
  types: {
    email: "Inserisci un email valida",
  },
};

const success = () => {
  message.success("Card salvata con successo");
};

const error = () => {
  message.error("Oops, si è vericato un errore");
};

const CardForm = (props) => {
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const { isProd, isMobile } = useAppContext();
  const { getTokenSilently } = useAuth0();
  const env = isProd ? "prod" : "dev";
  const history = useHistory();
  const location = useLocation();
  const { Option } = Select;

  const [companyName] = useQueryParam("companyName", StringParam);
  const [phone] = useQueryParam("phone", StringParam);
  const [firstName] = useQueryParam("firstName", StringParam);
  const [lastName] = useQueryParam("lastName", StringParam);
  const [mobile] = useQueryParam("mobile", StringParam);
  const [email] = useQueryParam("email", StringParam);
  const [companyBillingName] = useQueryParam("companyBillingName", StringParam);
  const [website] = useQueryParam("website", StringParam);
  const [billingAddress] = useQueryParam("billingAddress", StringParam);
  const [pec] = useQueryParam("pec", StringParam);
  const [vat] = useQueryParam("vat", StringParam);
  const [electronicInvoicingId] = useQueryParam(
    "electronicInvoicingId",
    StringParam
  );
  const [address] = useQueryParam("address", StringParam);
  const [city] = useQueryParam("city", StringParam);

  // Pre-propulate card when copying
  let card = props.location.state || location.record;

  if (
    card === undefined ||
    card?.mobilePrefix === undefined ||
    card?.phonePrefix === undefined
  ) {
    card = { ...card, mobilePrefix: "+39", phonePrefix: "+39" };
  }

  if (companyName) {
    card.companyName = companyName;
  }
  if (phone) {
    card.phone = phone;
  }
  if (firstName) {
    card.firstName = firstName;
  }
  if (lastName) {
    card.lastName = lastName;
  }
  if (mobile) {
    card.mobile = mobile;
  }
  if (email) {
    card.email = email;
  }
  if (companyBillingName) {
    card.companyBillingName = companyBillingName;
  }
  if (website) {
    card.website = website;
  }
  if (billingAddress) {
    card.billingAddress = billingAddress;
  }
  if (pec) {
    card.pec = pec;
  }
  if (vat) {
    card.vat = vat;
  }
  if (electronicInvoicingId) {
    card.electronicInvoicingId = electronicInvoicingId;
  }
  if (address) {
    card.address = address;
  }
  if (city) {
    card.city = city;
  }

  if (card.mobile && card.mobile.includes("+")) {
    card.mobilePrefix = card.mobile.slice(0, 3);
    card.mobile = card.mobile.slice(3);
  }

  if (card?.phone && card?.phone.includes("+")) {
    card.phonePrefix = card.phone.slice(0, 3);
    card.phone = card.phone.slice(3);
  }

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".it", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const prefixSelector = (prefix) => (
    <Form.Item name={["card", prefix]} noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+39">+39</Option>
        <Option value="+44">+44</Option>
        <Option value="">No</Option>
      </Select>
    </Form.Item>
  );

  const onSubmit = async ({ card }) => {
    const hide = message.loading("Salvataggio in corso...");
    const token = await getTokenSilently();

    let url = `${config.baseUrl[env]}/admin`;
    let httpMethod = "POST";

    if (location.record) {
      const slug = location.record.slug;
      url = `${url}?slug=${slug}`;
      httpMethod = "PATCH";
    }

    try {
      await fetch(url, {
        method: httpMethod,
        body: JSON.stringify({ card, forceNotification: false }),
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      hide();
      success();
    } catch (err) {
      console.error(err);
      hide();
      error();
      return;
    }

    history.push("/");
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onSubmit}
      validateMessages={validateMessages}
      initialValues={{
        card,
      }}
    >
      <Row>
        <Col span={isMobile ? 24 : 9}>
          <Divider>Anagrafica </Divider>
          <Form.Item
            name={["card", "firstName"]}
            label="Nome"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={["card", "lastName"]}
            label="Cognome"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["card", "mobile"]} label="Cellulare">
            <Input addonBefore={prefixSelector("mobilePrefix")} />
          </Form.Item>
          <Form.Item name={["card", "phone"]} label="Telefono Fisso">
            <Input addonBefore={prefixSelector("phonePrefix")} />
          </Form.Item>
          <Form.Item
            name={["card", "companyName"]}
            label="Nome Impresa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["card", "address"]} label="Indirizzo">
            <Input />
          </Form.Item>
          <Form.Item name={["card", "city"]} label="Città">
            <Input />
          </Form.Item>
          <Form.Item name={["card", "website"]} label="Sito Web">
            <AutoComplete
              options={websiteOptions}
              onChange={onWebsiteChange}
              placeholder="Website"
            >
              <Input />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            name={["card", "email"]}
            label="Email"
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={isMobile ? 24 : 9}>
          <Divider>Dati di Fatturazione</Divider>
          <Form.Item
            name={["card", "companyBillingName"]}
            label="Ragione Sociale"
          >
            <Input />
          </Form.Item>
          <Form.Item name={["card", "billingAddress"]} label="Indirizzo">
            <Input />
          </Form.Item>
          <Form.Item name={["card", "vat"]} label="Partita IVA">
            <Input />
          </Form.Item>
          <Form.Item name={["card", "pec"]} label="Pec">
            <Input />
          </Form.Item>
          <Form.Item
            name={["card", "electronicInvoicingId"]}
            label="Codice Destinatario"
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "25px" }}
            >
              {location.record ? "Aggiorna Card" : "Crea Card"}
            </Button>
            <Link to="/">
              <Button danger>Annulla</Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CardForm;
