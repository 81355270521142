import React, { useContext, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";

export const AppContext = React.createContext();
export const useAppContext = () => useContext(AppContext);

export const AppProvider = (props) => {
  const [isProd, setIsProd] = useLocalStorage("isProd", true);
  const [isMobile, setIsMobile] = useState(false);

  const toggleEnv = () => {
    setIsProd(!isProd);
  };

  const checkMobile = () => setIsMobile(window.innerWidth < 1000);
  window.onload = checkMobile;
  window.onresize = checkMobile;

  return (
    <AppContext.Provider
      value={{
        isProd,
        toggleEnv,
        isMobile,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
