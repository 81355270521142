import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import { Cards, PrivateRoute, Sidebar } from "./components";
import CardForm from "./components/Form/CardForm";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import "../node_modules/antd/dist/antd.css";
import { Spin, Layout } from "antd";
import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from "use-query-params";

const { Content } = Layout;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { loading } = useAuth0();

  const queryStringifyOptions = {
    transformSearchString: transformSearchStringJsonSafe,
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          zIndex: 999,
          textAlign: "center",
          marginTop: "20%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <QueryParamProvider
          ReactRouterRoute={Route}
          stringifyOptions={queryStringifyOptions}
        >
          <Layout style={{ minHeight: "100vh" }}>
            <Sidebar />
            <Layout className="site-layout">
              <Content style={{ padding: "20px", backgroundColor: "#fff" }}>
                <Switch>
                  <PrivateRoute path="/" exact component={Cards} />
                  <PrivateRoute path="/new" component={CardForm} />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
